import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { LabelThemeType } from "../../../types";
import { Checkbox } from "../checkbox";
import { RequiredIndicator } from "../required";
import { LabelStyled, LabelStyles } from "./LabelStyles";

export interface LabelProps {
  /** ID the label is for */
  htmlFor: string;
  /** if the label is required */
  required?: boolean;
  /** if the label is missed */
  missed?: boolean;
  /** if the label is complete */
  complete?: boolean;
  /** show NA on the label */
  showNA?: boolean;
  /** if the label has NA */
  isNA?: boolean;
  /** if the NA button was clicked */
  onChange?: (value: boolean) => void;
  /** classNames to pass to the label */
  className?: string;
  /** Overrideable styles */
  styles?: LabelStyles;
}

/**
 * LabelTypes for an input
 */
export const Label: React.FC<React.PropsWithChildren<LabelProps>> = ({
  children,
  htmlFor,
  required = false,
  missed = false,
  complete = false,
  className = "",
  showNA = false,
  isNA = false,
  onChange,
  styles,
  ...rest
}) => {
  const { Theme } = useTheme();
  const StylesOverride: LabelThemeType = { ...Theme.label, ...styles };

  const getStatus = () => {
    if (required) {
      if (missed) {
        return <span> - Missed</span>;
      } else if (complete) {
        return <span> - Complete</span>;
      }
    }
    return <span />;
  };

  const changeNA = () => {
    if (!!onChange) {
      onChange(!isNA);
    }
  };

  return (
    <LabelStyled htmlFor={htmlFor} styles={StylesOverride} className={clsx("bcr-label-text", className)} {...rest}>
      {children} {getStatus()} {required && <RequiredIndicator styles={StylesOverride}>*</RequiredIndicator>}
      {!!showNA && (
        <div className="ms-auto">
          <Checkbox id={`${htmlFor}-na`} checked={isNA} onChange={changeNA} label="N/A" />
        </div>
      )}
    </LabelStyled>
  );
};

export default Label;
