import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { ValidationMessageStyled } from "./ValidationMessageStyles";

export type ValidationTypes = "error" | "success";

export type ValidationMessageType = {
  /** type of message */
  type: ValidationTypes;
  /** message to display */
  message: string | React.ReactNode;
  /** function to do validation check */
  validationFunction: () => boolean;
  /** overrideable styles */
  styles?: ValidationMessageType;
};

const ValidationMessage: React.FC<React.PropsWithChildren<ValidationMessageType>> = ({
  message,
  validationFunction,
  type,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride = {
    validation: {
      ...Theme.validation,
      ...styles,
    },
  };

  return (
    <ValidationMessageStyled styles={StylesOverride} type={type} isInvalid={validationFunction()}>
      {message}
    </ValidationMessageStyled>
  );
};
export default ValidationMessage;
