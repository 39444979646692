import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../hooks";
import { CheckboxThemeType } from "../../../types";
import { BaseInputProps } from "../../../types/InputTypes";
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from "./CheckboxStyles";

export type CheckboxProps = BaseInputProps & {
  /** if the checkbox is checked */
  checked: boolean;
  /** change event for when the checkbox is toggled */
  onChange: (value: boolean) => void;
  /** overrideable styles */
  styles?: CheckboxThemeType;
};

/**
 * a Form element checkbox
 */
const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  id,
  checked,
  onChange,
  label,
  className = "",
  disabled = false,
  readOnly = false,
  missed = false,
  styles = {},
  gutterBottom = false,
  ...rest
}) => {
  const { Theme } = useTheme();
  const StylesOverride: CheckboxThemeType = { ...Theme.checkbox, ...styles };

  const changed = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!disabled && !readOnly) {
      onChange(!checked);
    }
  };

  return (
    <CheckboxContainer
      onClick={changed}
      styles={StylesOverride}
      disabled={disabled || readOnly}
      className={clsx(className, className, { "mb-2": gutterBottom })}
    >
      <CheckboxInput
        id={id}
        className={clsx("bcr-checkbox-input")}
        checked={checked}
        disabled={disabled}
        readonly={readOnly}
        styles={StylesOverride}
        {...rest}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        onChange={() => {
          console.debug("Check changed ignored");
        }}
      />
      <CheckboxLabel
        htmlFor={id}
        disabled={disabled || readOnly}
        styles={StylesOverride}
        className={clsx("bcr-checkbox-label")}
      >
        {label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default Checkbox;
