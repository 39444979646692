import clsx from "clsx";
import React, { forwardRef, useEffect, useRef } from "react";
import { Hint } from "../../../components";
import Icon from "../../../components/icon/Icon";
import { useTheme } from "../../../hooks";
import { Typography } from "../../../layout";
import {
  BaseInputProps,
  InputSize,
  InputThemeType,
  InputType,
  LabelThemeType,
  PlaceholderThemeType,
  ReadOnlyFormatterProps,
} from "../../../types";
import { isEmpty, isNA } from "../../../utils";
import RequiredInput from "../../required/RequiredInput";
import { ValidationMessageType } from "../../validation";
import ValidationMessages from "../../validation/ValidationMessages";
import Label from "../label/Label";
import { InputAdorner, InputGroup, InputStyled, LabelContainer } from "./InputStyles";

type InputStyles = {
  placeholder?: PlaceholderThemeType;
  input?: InputThemeType;
  label?: LabelThemeType;
};

export type InputProps = BaseInputProps & {
  /** type of the input */
  type?: InputType;
  /** Function called when changed */
  onChange: (value: any) => void;
  /** Value of the input */
  value?: number | string;
  /** If the input failed required checks */
  failedRequired?: boolean;
  /** validation messages */
  validationMessages?: ValidationMessageType[];
  /** end adorner for the input field */
  endAdorner?: React.ReactNode;
  /** start adorner for the input field */
  startAdorner?: React.ReactNode;
  /** step for a number input */
  step?: number;
  /** min for number input */
  min?: number;
  /** max for number input */
  max?: number;
  /** max length of a text input */
  maxLength?: number;
  /** styles to override the specific input */
  styles?: InputStyles;
};

const getSize = (size?: InputSize): string => {
  switch (size) {
    case "sm":
      return "30px";
    case "lg":
      return "50px";
    case "md":
    default:
      return "38px";
  }
};

export const ReadOnlyFormatter: React.FC<React.PropsWithChildren<ReadOnlyFormatterProps>> = ({
  value,
  id,
  onClick,
}) => (
  <Typography id={id} variant="div" onClick={onClick}>
    {value}
  </Typography>
);

const InputField = forwardRef(
  (
    {
      id,
      type = "text",
      onChange,
      value,
      label,
      className,
      clearable = false,
      required = false,
      placeholder = "",
      disabled = false,
      missed = false,
      styles = {} as InputStyles,
      failedRequired = false,
      endAdorner,
      startAdorner,
      gutterBottom = false,
      maxLength,
      onBlur,
      onFocus,
      disablePaste = false,
      ...rest
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { Theme } = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const StylesOverride = {
      input: { ...Theme.input, ...styles.input },
      placeholder: { ...Theme.placeholder, ...styles.placeholder },
      label: { ...Theme.label, ...styles.label },
    };
    if (!required && className?.includes("is-invalid")) {
      className = className?.replace("is-invalid", "");
    }
    const hasStartAdorner = !!startAdorner;
    const hasEndAdorner: boolean = !!endAdorner || !!clearable;

    useEffect(() => {
      if (!!inputRef && !!ref) {
        //@ts-ignore
        ref.current = inputRef.current;
      }
    }, [inputRef]);

    useEffect(() => {
      if (inputRef?.current) {
        if (disablePaste) {
          inputRef.current.onpaste = (e: ClipboardEvent) => {
            e.preventDefault();
          };
        } else {
          inputRef.current.onpaste = (e: ClipboardEvent) => {
            //leave default functionality
          };
        }
      }
    }, [inputRef, disablePaste]);

    return (
      <div className={clsx({ "mb-2": gutterBottom })}>
        <InputGroup
          $failedRequired={failedRequired}
          $missed={missed}
          $styles={StylesOverride}
          $classes={clsx(className)}
          disabled={disabled}
        >
          {!!startAdorner && <InputAdorner $styles={StylesOverride}>{startAdorner}</InputAdorner>}
          <InputStyled
            ref={inputRef}
            id={id}
            type={type}
            className={clsx("form-control bcr-text-input", className)}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            placeholder={placeholder}
            $clearable={clearable}
            $styles={StylesOverride}
            $missed={missed}
            $failedRequired={failedRequired}
            disabled={disabled}
            autoComplete="off"
            $hasEndAdorner={hasEndAdorner}
            $hasStartAdorner={hasStartAdorner}
            onBlur={onBlur}
            onFocus={onFocus}
            maxLength={maxLength}
            {...rest}
          />
          {hasEndAdorner && (
            <InputAdorner $styles={StylesOverride}>
              {!!clearable && !isEmpty(value) ? (
                <Icon iconName="fa-times-circle" onClick={() => onChange("")} />
              ) : (
                endAdorner
              )}
            </InputAdorner>
          )}
        </InputGroup>
        {!!maxLength && (
          <Hint>
            {value?.length || 0} / {maxLength}
          </Hint>
        )}
      </div>
    );
  },
);

const Input = forwardRef(
  (
    {
      id,
      type = "text",
      onChange,
      value,
      label,
      readOnly = false,
      clearable = false,
      required = false,
      placeholder = "",
      disabled = false,
      missed = false,
      styles = {} as InputStyles,
      readOnlyFormatter = ReadOnlyFormatter,
      validationMessages,
      failedRequired = true,
      showNA = false,
      gutterBottom = false,
      className = "",
      min,
      max,
      inputSize = "md",
      onClick,
      validateFunc,
      ...rest
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const ReadOnlyFormatterWrap = readOnlyFormatter;
    const { Theme } = useTheme();
    const StylesOverride = {
      input: { ...Theme.input, ...styles.input, height: getSize(inputSize) },
      placeholder: { ...Theme.placeholder, ...styles.placeholder },
      label: { ...Theme.label, ...styles.label },
    };

    const toggleNA = (value: boolean) => {
      if (value) {
        onChange("N/A");
      } else {
        onChange("");
      }
    };

    const localOnChange = (value?: string) => {
      if (type === "number") {
        const numberValue = Number(value);
        const hasMax: boolean = max !== undefined;
        const hasMin: boolean = min !== undefined;
        if (!!value) {
          if (hasMax && hasMin) {
            if (numberValue >= min! && numberValue <= max!) {
              onChange(value);
            }
          } else if (hasMax) {
            if (numberValue <= max!) {
              onChange(value);
            }
          } else if (hasMin) {
            if (numberValue >= min!) {
              onChange(value);
            }
          } else {
            onChange(value);
          }
        } else {
          onChange(value);
        }
      } else {
        onChange(value);
      }
    };

    const isNAValue: boolean = isNA(showNA, value);
    return (
      <>
        {!!label && (
          <LabelContainer $styles={Theme} $hasValidation={!!validationMessages?.length}>
            <Label
              htmlFor={id}
              required={required && !readOnly}
              missed={missed}
              complete={required && !failedRequired}
              showNA={showNA && !readOnly}
              isNA={isNAValue}
              onChange={toggleNA}
              styles={StylesOverride.label}
            >
              {label}
            </Label>
            <ValidationMessages validationMessages={validationMessages} />
          </LabelContainer>
        )}
        {readOnly ? (
          //@ts-ignore
          <div className={clsx({ "mb-2": gutterBottom })}>
            <ReadOnlyFormatterWrap id={id} value={value} onClick={onClick} />
          </div>
        ) : (
          <RequiredInput required={required} isNA={isNAValue} validateFunc={validateFunc}>
            <InputField
              ref={ref}
              id={id}
              type={type}
              onChange={localOnChange}
              value={!isNAValue ? value : ""}
              label={label}
              clearable={clearable}
              placeholder={placeholder}
              styles={StylesOverride}
              missed={missed}
              disabled={disabled || isNAValue}
              className={clsx(className, { "is-na": isNAValue })}
              min={min}
              max={max}
              gutterBottom={gutterBottom}
              {...rest}
            />
          </RequiredInput>
        )}
      </>
    );
  },
);

export default Input;
